import PostApi from "@/services/postApi"

const api = new PostApi()

const state = () => ({
  list: [],
  item: {},
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 10,
    from: 1,
    to: 1,
  },
  files: [],
  originFiles: [],
  active: "",
  form: {
    content: "",
    button_type: 0,
    button_link: null,
    phone_number: "",
    date_time_publish: "",
    date_time_remove: "",
    file: [],
  },
  error: "",
  errors: [],
})

const mutations = {
  setModels(state, { data }) {
    state.list = data.data.data
    state.pagination = {
      current_page: data.data.current_page,
      last_page: data.data.last_page,
      total: data.data.total,
      per_page: Number(data.data.per_page),
      from: data.data.from,
      to: data.data.to,
    }
  },

  setModel(state, { data }) {
    state.item = data.data[0]
  },

  resetStateMutation(state) {
    const initialState = {
      list: [],
      item: {},
      pagination: {
        current_page: 1,
        last_page: 1,
        total: 0,
        per_page: 10,
        from: 1,
        to: 1,
      },
      files: [],
      active: "",
      form: {
        content: "",
        button_type: 0,
        button_link: null,
        phone_number: "",
        date_time_publish: "",
        date_time_remove: "",
        file: [],
      },
      error: "",
    }

    Object.assign(state, initialState)
  },

  setFiles(state, data) {
    state.files = data
  },

  setOriginFiles(state, data) {
    state.originFiles = data
  },

  setActive(state, data) {
    state.active = data
  },

  setForm(state, data) {
    state.form = data
  },

  setError(state, data) {
    state.error = data
  },

  setErrors(state, data) {
    state.errors = data
  },
}

const actions = {
  async getModels({ commit }, { userId, params }) {
    await api.getModels({ userId, params }).then((res) => {
      commit("setModels", res)
    })
  },

  async getModel({ commit }, { id, userId }) {
    await api.getModel({ id, userId }).then((res) => {
      commit("setModel", res)
    })
  },

  async create({ commit }, { data }) {
    await api.create({ data })
  },

  resetState({ commit }) {
    commit("resetStateMutation")
  },

  setFiles({ commit }, data) {
    commit("setFiles", data)
  },

  setOriginFiles({ commit }, data) {
    commit("setOriginFiles", data)
  },

  setActive({ commit }, data) {
    commit("setActive", data)
  },

  setForm({ commit }, data) {
    commit("setForm", data)
  },

  setError({ commit }, data) {
    commit("setError", data)
  },

  setErrors({ commit }, data) {
    commit("setErrors", data)
  },
}

export default { namespaced: true, state, mutations, actions }
