<template>
  <div v-if="isLoaded" class="post-content">
    <tabs :value="activeName" @active="handleActiveName">
      <tab-pane
        v-for="tab in tabs"
        :key="tab.name"
        :id="tab.name"
        :title="tab.name"
      >
        <template slot="title">
          <div>
            <img
              :src="tab.url"
              width="30"
              height="30"
              :alt="tab.label"
              class="mr-2"
            />
            <span>{{ tab.label }}</span>
          </div>
        </template>
      </tab-pane>
    </tabs>
    <PostForm :name="active" :type="type" />
  </div>
</template>

<script>
import { Tabs, TabPane } from "src/components/UIComponents"
import PostForm from "./PostForm"
import { convertMessage } from "src/util/commons"
import { postType, connectionStatusType } from "src/util/constants"
import { mapState, mapActions } from "vuex"

export default {
  components: {
    TabPane,
    Tabs,
    PostForm,
  },

  props: {
    type: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      activeName: null,
      tabs: postType,
      connectionStatusType,
      isLoaded: false,
    }
  },

  computed: {
    ...mapState("post", ["item", 'active']),
    ...mapState("publisher", ["list"]),
    userIds() {
      return this.$store.getters.userIds
    },
  },

  async mounted() {
    await this.initData()
  },

  methods: {
    ...mapActions("entity", ["getBasicInformation"]),
    ...mapActions("post", ["getModel", "setActive"]),
    ...mapActions("publisher", ["getPublisher"]),
    async initData() {
      this.$store.commit("setShowLoading", true)
      try {
        const data = {
          user_id: this.userIds,
        }
        await this.getBasicInformation({ data })
        await this.getPublisher({ data })
        this.handleSyncTabs()
        const id = this.$route.params.id
        if (id) await this.getModel({ id, userId: this.userIds })
        if (this.item) this.activeName = this.item.shared_sns_type
        this.isLoaded = true
        if (id) await this.getModel({ id, userId: this.userIds })
        if (this.$route.path.includes("create-post"))
          this.setActive("google_my_business")
      } catch (error) {
        if (error.response?.data?.errors) {
          this.$notify({
            message: convertMessage(error.response.data.errors).join("\r\n"),
            type: "danger",
          })
        } else {
          this.$notify({
            message: this.$t("actions.messages.postError"),
            type: "danger",
          })
        }
      } finally {
        this.$store.commit("setShowLoading", false)
      }
    },
    handleActiveName(value) {
      this.setActive(value)
    },
    handleSyncTabs() {
      const publisherKeysSet = new Set(
        this.list.map((item) => item.publisher_key)
      )
      const filteredTabs = this.tabs.filter((tab) => {
        if (publisherKeysSet.has(tab.name)) {
          if (tab.name === "facebook" || tab.name === "instagram") {
            const hasFacebook = this.list.some(
              (item) => item.publisher_key === "facebook" && item.status === 2
            )
            if (hasFacebook) {
              return true
            }
          } else {
            return this.list.some(
              (item) => item.publisher_key === tab.name && item.status === 2
            )
          }
        }
        return false
      })
      this.tabs = filteredTabs
    },
  },
}
</script>
