import screens from "../lang/ja/screens"

export const messageType = {
  SUCCESS: "success",
  ERROR: "error",
}

export const userType = {
  USER: 0,
  OWNER: 1,
  ADMIN: 2,
}

export const roleType = {
  FREETRIAL: 0,
  SHOP: 1,
  AGENCY: 11,
  MANAGER: 99,
}

export const registerType = {
  STORE: 0,
  AGENCY: 1,
}

export const freeTrialType = {
  FREETRIAL_AVAILABILITY: 1,
  FREETRIAL_EXPIRE: 2,
}

export const FREETRIAL_ALLOW_SNS = [1, 2, 3, 7]

export const IMAGE_SIZE = 3072

export const monthOptions = [
  { value: "01", label: "1月" },
  { value: "02", label: "2月" },
  { value: "03", label: "3月" },
  { value: "04", label: "4月" },
  { value: "05", label: "5月" },
  { value: "06", label: "6月" },
  { value: "07", label: "7月" },
  { value: "08", label: "8月" },
  { value: "09", label: "9月" },
  { value: "10", label: "10月" },
  { value: "11", label: "11月" },
  { value: "12", label: "12月" },
]

export const connectionStatusType = {
  DISCONNECT: 0,
  CONNECTED: 1,
  SYNC: 2,
  SUBMITTED: 3,
  ERROR: 4,
  REQUESTING: 5,
}

export const addNewsOptions = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1,
    label: "Book",
  },
  {
    value: 2,
    label: "Order online",
  },
  {
    value: 3,
    label: "Buy",
  },
  {
    value: 4,
    label: "Learn more",
  },
  {
    value: 5,
    label: "Sign up",
  },
  {
    value: 6,
    label: "Call now",
  },
]

export const addEventOptions = addNewsOptions

export const addProductOptions = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1,
    label: "Order online",
  },
  {
    value: 2,
    label: "Buy",
  },
  {
    value: 3,
    label: "Learn more",
  },
  {
    value: 4,
    label: "Get offer",
  },
]

export const categoryProductOptions = [
  {
    id: 0,
    name: "Create a new category",
  },
]

export const creditCardType = {
  AMERICAN_EXPRESS: "AMEX",
  DINERS_CLUB: "D",
  JCB: "J",
  MASTERCARD: "M",
  VISA: "V",
}

export const scriptTagHead = `<script async src="`
export const scriptTagFooter = `" type="text/javascript"></script>`
export const scriptTagPixelsHead = `<script>
  if (!yext) {
    window.yCookieOptInQ = window.yCookieOptInQ || [];
    window.yPixelOptInQ = window.yPixelOptInQ || [];
    var yext = {
      'cookie': { 'optIn': function() { window.yCookieOptInQ.push(arguments); } },
      'pixel': { 'optIn': function() { window.yPixelOptInQ.push(arguments); } }
    };
  }
  `
export const scriptTagPixelsFooter = `
</script>

`
export const scriptTagPixels = [
  "",
  `yext.pixel.optIn(true);`,
  `yext.pixel.optIn(false);`,
]
export const scriptTagCookies = [
  "",
  `
  yext.cookie.optIn(true);`,
  `
  yext.cookie.optIn(false);`,
]

export const imageType = [
  "image/jpg",
  "image/jpeg",
  "image/jpe",
  "image/jif",
  "image/jfif",
  "image/jfi",
  "image/png",
  "image/gif",
  "image/webp",
  "image/tiff",
  "image/tif",
  "image/psd",
  "image/raw",
  "image/arw",
  "image/cr2",
  "image/nrw",
  "image/k25",
  "image/bmp",
  "image/dib",
  "image/heif",
  "image/heic",
  "image/ind",
  "image/indd",
  "image/indt",
  "image/jp2",
  "image/j2k",
  "image/jpf",
  "image/jpx",
  "image/jpm",
  "image/mj2",
  "image/svg+xml",
  "image/svgz",
  "image/ai",
  "image/eps",
  "image/pdf",
]

export const imageExtensionType = [
  "jpg",
  "jpeg",
  "jpe",
  "jif",
  "jfif",
  "jfi",
  "png",
  "gif",
  "webp",
  "tiff",
  "tif",
  "psd",
  "raw",
  "arw",
  "cr2",
  "nrw",
  "k25",
  "bmp",
  "dib",
  "heif",
  "heic",
  "ind",
  "indd",
  "indt",
  "jp2",
  "j2k",
  "jpf",
  "jpx",
  "jpm",
  "mj2",
  "svg",
  "svgz",
  "ai",
  "eps",
  "pdf",
]

export const videoType = [
  "video/webm",
  "video/mpg",
  "video/mp2",
  "video/mpeg",
  "video/mpe",
  "video/mpv",
  "video/ogg",
  "video/mp4",
  "video/m4p",
  "video/m4v",
  "video/avi",
  "video/x-ms-wmv",
  "video/mov",
  "video/qt",
  "video/flv",
  "video/swf",
  "video/avchd",
]

export const videoExtensionType = [
  "webm",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "ogg",
  "mp4",
  "m4p",
  "m4v",
  "avi",
  "wmv",
  "mov",
  "qt",
  "flv",
  "swf",
  "avchd",
]

export const shareType = {
  GBP: "google_my_business",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  TWITTER: "twitter",
}

export const postType = [
  {
    label: "Google Business Profile",
    name: "google_my_business",
    url: process.env.VUE_APP_API_ENDPOINT + "images/publisher/google_my_business.svg",
  },
  {
    label: "Facebook",
    name: "facebook",
    url: process.env.VUE_APP_API_ENDPOINT + "images/publisher/facebook.svg",
  },
  {
    label: "Instagram",
    name: "instagram",
    url: process.env.VUE_APP_API_ENDPOINT + "images/publisher/instagram.svg",
  },
  {
    label: "X",
    name: "twitter",
    url: process.env.VUE_APP_API_ENDPOINT + "images/publisher/X.png",
  },
]

export const postKeyType = {
  ALL: "all",
  OFFER: "offer",
  WHAT_NEW: "what-new",
  EVENT: "event",
  PRODUCT: "product",
}

export const timesType = {
  OPEN: 0,
  ALL_DAYS: 1,
  CLOSED: 2,
}

export const planPriceType = [
  {
    id: 1,
    name: `${screens.dashboard.texts.standard}`,
    price: `${screens.paymentSetting.paymentStandard}`,
  },
  {
    id: 2,
    name: `${screens.dashboard.texts.premium}`,
    price: `${screens.paymentSetting.paymentPremium}`,
  },
]

export const paymentStatusType = {
  SUCCESS: "12999",
  FAIL: "18999",
}

export const citationType = {
  GMB: "google_my_business",
}

export const mediaGmbType = {
  PHOTO: "PHOTO",
  VIDEO: "VIDEO",
}

export const mediaType = {
  IMAGE: "image",
  VIDEO: "video",
}

export const domainType = {
  MEO_SYSTEM: {
    key: "meo-system",
    title: "MEO-SYSTEM",
    url: "/meo-system/favicon.png",
  },
  LIGHTEN: {
    key: "lighten-meo",
    title: "LIGHTEN",
    url: "/lighten-meo/favicon.png",
  },
}

export const knowledgeAttribute = {
  MEO_SYSTEM: {
    data_field: "data-meo-system-field",
    data_id: "data-meo-system-id",
  },
  LIGHTEN: {
    data_field: "data-lighten-field",
    data_id: "data-lighten-id",
  },
}

export const snsExtensionType = {
  GBP: ".jpg, .jpeg, .png, .heic",
  FACEBOOK: ".jpeg, .bmp, .png, .gif, .tiff, .heic, .mov, .mp4",
  INSTAGRAM: " .jpeg, .gif, .png, .heic, .mov, .mp4",
  TWITTER: ".jpeg, .gif, .png, .heic, .mov, .mp4",
}

export const extensionType = {
  GBP: ["jpg", "jpeg", "png", "heic"],
  FACEBOOK: ["jpeg", "bmp", "png", "gif", "tiff", "heic", "mov", "mp4"],
  INSTAGRAM: ["jpeg", "gif", "png", "heic", "mov", "mp4"],
  TWITTER: ["jpeg", "gif", "png", "heic", "mov", "mp4"],
}

export const fileLimitType = {
  MIN: "min",
  MAX: "max",
  NOT: "not",
}

export const fileNumberType = {
  GBP: 1,
  FACEBOOK: 80,
  INSTAGRAM: 10,
  TWITTER: 4,
}

export const KB = 1024
export const MB = 1024 * KB
export const GB = 1024 * MB